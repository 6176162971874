import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoryPageComponent, UiTemplateComponent } from '@core';
import { Role } from '@domains';
import { AuthGuard, RoleGuard } from '@rspl-auth';
import { HealthCheckComponent } from '@rspl-ui';

const routes: Routes = [
  {
    path: '',
    component: UiTemplateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [Role.CAPTAIN] },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@donations').then((module) => module.DonationsRootModule),
      },
      {
        path: 'charities',
        loadChildren: () =>
          import('@charities').then((module) => module.CharitiesModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('@users').then((module) => module.UsersRootModule),
      },
      {
        path: 'partners',
        loadChildren: () =>
          import('@partners').then((module) => module.PartnersRootModule),
      },
      {
        path: 'markets',
        loadChildren: () =>
          import('@markets').then((module) => module.MarketsRootModule),
      },
      {
        path: 'xmiles',
        loadChildren: () =>
          import('@xmiles').then((module) => module.XmilesModule),
      },
      {
        path: 'public-reviews',
        loadChildren: () =>
          import('@public-reviews').then((module) => module.PublicReviewsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('@notifications').then((module) => module.NotificationsModule),
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('@organizations').then((module) => module.OrganizationsModule),
      },
      {
        path: 'journeys',
        loadChildren: () =>
          import('@journeys').then((module) => module.JourneysModule),
      },
      {
        path: 'items',
        loadChildren: () =>
          import('@items').then((module) => module.ItemsRootModule),
      },
      {
        path: 'features',
        loadChildren: () =>
          import('@features').then((module) => module.FeaturesRootModule),
      },
      {
        path: 'trp',
        loadChildren: () => import('@trp').then((module) => module.TrpModule),
      },
      {
        path: 'phone-configurations',
        loadChildren: () => import('@phone-configurations').then((module) => module.PhoneConfigurationsModule),
      },
      {
        path: 'playback',
        loadChildren: () => import('@playback').then((module) => module.PlaybackModule),
      },
      {
        path: 'history',
        component: HistoryPageComponent,
      },
    ],
  },
  {
    path: 'hc',
    component: HealthCheckComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
